import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'

import { AuthContext } from './AuthContext'
import { onNotLogged, onTokenExpired, onUnexpected } from './commons'
import { UserPreferencesProvider } from '../preferences/UserPreferencesProvider'
import { getClient } from '../../graphql/client'

import { User } from '../../userprofile/types/user'
import { transformAuth0UserToUser, validateAuth0User } from '../../userprofile/auth0'
import { matchAuthenticationErrors } from '../../userprofile/errors/common'
import { LoadingCircular } from '../../components/LoadingCircular'

import { useLDClient } from 'launchdarkly-react-client-sdk'

const AuthProvider: React.FC = ({ children }) => {
  const ldClient = useLDClient()
  const [user, setUser] = React.useState<Maybe<User>>()
  const { isAuthenticated, logout, getIdTokenClaims, getAccessTokenSilently, loginWithRedirect } = useAuth0()

  React.useEffect(
    function () {
      getIdTokenClaims()
        .then(validateAuth0User)
        .then(transformAuth0UserToUser)
        .then(setUser)
        .then(() => getAccessTokenSilently())
        .catch(
          matchAuthenticationErrors({
            noLogged: onNotLogged(loginWithRedirect),
            tokenExpired: onTokenExpired(logout),
            unexpected: onUnexpected(logout),
          }),
        )
    },
    [getAccessTokenSilently, getIdTokenClaims, loginWithRedirect, logout],
  )

  if (!user) {
    return <LoadingCircular />
  }

  ldClient?.identify({
    kind: 'user',
    key: user.email,
    email: user.email,
  })

  const graphqlClient = getClient(user.token)

  return (
    <AuthContext.Provider value={{ user, logout }}>
      <ApolloProvider client={graphqlClient}>
        <UserPreferencesProvider user={user} isAuthenticated={isAuthenticated}>
          {children}
        </UserPreferencesProvider>
      </ApolloProvider>
    </AuthContext.Provider>
  )
}

export default AuthProvider
