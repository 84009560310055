import { LogoutOptions, RedirectLoginOptions } from '@auth0/auth0-react'
import { User } from '../../userprofile/types/user'
import { analytics } from '../../utils/analytics'
import { Monitoring } from '../../utils/monitoring'

type Login = (options?: RedirectLoginOptions | undefined) => Promise<void>
type Logout = (options?: LogoutOptions | undefined) => void

export const onNotLogged = (login: Login) => {
  return async (error: Error) => {
    Monitoring.error(error, { source_url: window.location.href })
    await analytics.track('SSO - Redirected', { source_url: window.location.href, error: error?.message })
    await login()
  }
}

export const onUnexpected = (logout: Logout) => {
  return async (error: Error) => {
    Monitoring.error(error, { source_url: window.location.href })
    await analytics.track('User - Sign in fail', { source_url: window.location.href, error: error?.message })

    try {
      logout({ logoutParams: { returnTo: window.ENV.REACT_APP_URL } })
    } catch (e) {
      Monitoring.error(new Error(String(e)), {
        kind: 'logout',
      })
    }
  }
}

export const onTokenExpired = (logout: Logout) => {
  return async (error: Error) => {
    Monitoring.error(error, { source_url: window.location.href })
    await analytics.track('User - Signed Out', { source_url: window.location.href, error: error?.message })

    try {
      const language = localStorage.getItem('language') || ''
      logout({ logoutParams: { returnTo: window.ENV.REACT_APP_URL } })
      localStorage.setItem('language', language)
    } catch (e) {
      Monitoring.error(new Error(String(e)), {
        kind: 'logout',
      })
    }
  }
}

export const transformAzureUserToUser = async (idTokenClaims: any): Promise<User> => {
  const user: User = {
    type: 'logged',
    username: idTokenClaims.email || '',
    token: idTokenClaims.idToken || '',
    email: idTokenClaims.email || '',
    givenName: idTokenClaims.given_name || '',
    familyName: idTokenClaims.family_name || '',
    picture: idTokenClaims.picture || '',
    sub: idTokenClaims.sub,
    groupId: idTokenClaims.groups || [],
  }
  return user
}

export const validateAzureUser = async (idTokenClaims: any): Promise<any> => {
  if (!idTokenClaims || !idTokenClaims.sub) {
    throw new Error('Invalid ID token claims')
  }
  return idTokenClaims
}

export const matchAuthenticationErrors =
  (handlers: {
    noLogged: (error: Error) => void
    tokenExpired: (error: Error) => void
    unexpected: (error: Error) => void
  }) =>
  async (error: Error) => {
    if (error.message.includes('no_logged')) {
      Monitoring.error(error, { source_url: window.location.href })
      await analytics.track('SSO - Redirected', { source_url: window.location.href, error: error?.message })
      handlers.noLogged(error)
    } else if (error.message.includes('token_expired')) {
      Monitoring.error(error, { source_url: window.location.href })
      await analytics.track('User - Signed Out', { source_url: window.location.href, error: error?.message })

      try {
        const language = localStorage.getItem('language') || ''
        handlers.tokenExpired(error)
        localStorage.setItem('language', language)
      } catch (e) {
        Monitoring.error(new Error(String(e)), {
          kind: 'logout',
        })
      }
    } else {
      Monitoring.error(error, { source_url: window.location.href })
      await analytics.track('User - Sign in fail', { source_url: window.location.href, error: error?.message })

      try {
        handlers.unexpected(error)
      } catch (e) {
        Monitoring.error(new Error(String(e)), {
          kind: 'logout',
        })
      }
    }
  }
