import { Configuration } from '@azure/msal-browser'

export const azureAuthConfig: Configuration = {
  auth: {
    clientId: window.ENV.REACT_APP_AZURE_CLIENT_ID,
    authority: window.ENV.REACT_APP_AZURE_AUTHORITY_URL,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    knownAuthorities: window.ENV.REACT_APP_AZURE_KNOWN_AUTHORITIES.split(','),
  },
  system: {
    allowNativeBroker: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
}
