import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { useMsal, useAccount } from '@azure/msal-react'
import { InteractionStatus, InteractionRequiredAuthError, SilentRequest } from '@azure/msal-browser'

import { AuthContext } from './AuthContext'
import { onNotLogged, onTokenExpired, onUnexpected } from './commons'
import { UserPreferencesProvider } from '../preferences/UserPreferencesProvider'
import { getClient } from '../../graphql/client'

import { User } from '../../userprofile/types/user'
import { transformAzureUserToUser, validateAzureUser, matchAuthenticationErrors } from './commons'
import { LoadingCircular } from '../../components/LoadingCircular'

import { useLDClient } from 'launchdarkly-react-client-sdk'

const AuthProviderAzure: React.FC = ({ children }) => {
  const ldClient = useLDClient()
  const [user, setUser] = React.useState<User | null>(null)
  const { instance: AzureInstance, accounts, inProgress } = useMsal()

  const account = useAccount(accounts[0] || {})

  React.useEffect(() => {
    if (account && inProgress === InteractionStatus.None) {
      const silentRequest: SilentRequest = {
        scopes: ['openid', 'profile', 'offline_access'],
        account: account,
      }

      AzureInstance.acquireTokenSilent(silentRequest)
        .then((response) => {
          const idTokenClaims = {
            ...response.idTokenClaims,
            idToken: response.idToken,
          }
          validateAzureUser(idTokenClaims)
            .then(transformAzureUserToUser)
            .then(setUser)
            .catch(
              matchAuthenticationErrors({
                noLogged: onNotLogged(() => AzureInstance.loginRedirect(silentRequest)),
                tokenExpired: onTokenExpired(() => AzureInstance.logoutRedirect()),
                unexpected: onUnexpected(() => AzureInstance.logoutRedirect()),
              }),
            )
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            AzureInstance.acquireTokenRedirect(silentRequest)
          } else {
            matchAuthenticationErrors({
              noLogged: onNotLogged(() => AzureInstance.loginRedirect(silentRequest)),
              tokenExpired: onTokenExpired(() => AzureInstance.logoutRedirect()),
              unexpected: onUnexpected(() => AzureInstance.logoutRedirect()),
            })(error)
          }
        })
    } else if (accounts.length === 0 && inProgress === InteractionStatus.None) {
      AzureInstance.loginRedirect()
    }
  }, [account, accounts.length, inProgress, AzureInstance])

  if (!user || accounts.length === 0) {
    return <LoadingCircular />
  }

  ldClient?.identify({
    kind: 'user',
    key: user.email,
    email: user.email,
  })

  const graphqlClient = getClient(user.token)

  return (
    <AuthContext.Provider value={{ user, logout: () => AzureInstance.logoutRedirect() }}>
      <ApolloProvider client={graphqlClient}>
        <UserPreferencesProvider user={user} isAuthenticated={true}>
          {children}
        </UserPreferencesProvider>
      </ApolloProvider>
    </AuthContext.Provider>
  )
}

export default AuthProviderAzure
