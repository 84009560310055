import React from 'react'
import { Router } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'

import routes from './routes'
import store from './store'
import GlobalStyle from './style/GlobalStyle'
import LaunchDarklyProvider from './contexts/launchDarkly/LaunchDarklyProvider'
import I18nProvider from './contexts/i18nProvider'

import { MainLayoutWrapper } from './components/MainLayoutWrapper'
import { LoadingCircular } from './components/LoadingCircular'
import SettingsProvider from './pages/UserSettings/SettingsProvider'
import CountriesContextProvider from './contexts/countries/CountriesContextProvider'

import { IPublicClientApplication } from '@azure/msal-browser'
import CustomNavigationClient from './utils/CustomNavigationClient'
import AuthProviderSwitch from './contexts/auth/AuthProviderSwitch'

const PublishProvider = React.lazy(
  () => import(/* webpackChunkName: "PublishProvider" */ './pages/Publish/provider/PublishProvider'),
)

interface AppProps {
  azurePublicClientApp: IPublicClientApplication
}

const App: React.FC<AppProps> = (props: AppProps) => {
  const history = createBrowserHistory()

  const navigationClient = new CustomNavigationClient(history)
  props.azurePublicClientApp.setNavigationClient(navigationClient)

  return (
    <GlobalStyle>
      <React.Suspense fallback={<LoadingCircular />}>
        <Router history={history}>
          <Switch>
            <Route component={PublishProvider} path={routes.publish} />
            <Route>
              <Provider store={store}>
                <I18nProvider>
                  <LaunchDarklyProvider>
                    <AuthProviderSwitch azurePublicClientApp={props.azurePublicClientApp}>
                      <SettingsProvider>
                        <CountriesContextProvider>
                          <MainLayoutWrapper />
                        </CountriesContextProvider>
                      </SettingsProvider>
                    </AuthProviderSwitch>
                  </LaunchDarklyProvider>
                </I18nProvider>
              </Provider>
            </Route>
          </Switch>
        </Router>
      </React.Suspense>
    </GlobalStyle>
  )
}

export default App
