import React from 'react'
import { MsalProvider } from '@azure/msal-react'
import AuthProvider from './AuthProvider'
import AuthProviderAzure from './AuthProviderAzure'
import Auth0ProviderWrapper from './Auth0ProviderWrapper'
import { useFlags } from 'launchdarkly-react-client-sdk'

const AuthProviderSwitch = ({ azurePublicClientApp, children }) => {
  const { azureMigration } = useFlags()

  const isAzureOn = azureMigration === 'complete'

  console.info('%cAzure Flag:%c %s', 'font-weight: bold;', '', azureMigration)
  console.info(
    '%cAuthProvider: %c%s',
    'font-weight: bold;',
    `font-weight: bold; color: ${isAzureOn ? 'blue' : 'red'};`,
    isAzureOn ? 'Azure' : 'Auth0',
  )

  if (isAzureOn) {
    return (
      <MsalProvider instance={azurePublicClientApp}>
        <AuthProviderAzure>{children}</AuthProviderAzure>
      </MsalProvider>
    )
  } else {
    return (
      <Auth0ProviderWrapper>
        <AuthProvider>{children}</AuthProvider>
      </Auth0ProviderWrapper>
    )
  }
}

export default AuthProviderSwitch
