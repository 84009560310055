import { NavigationClient } from '@azure/msal-browser'
import { History } from 'history'

/**
 * Custom navigation client for MSAL (Azure) that uses history object from React Router.
 */
export default class CustomNavigationClient extends NavigationClient {
  private history: History

  constructor(history: History) {
    super()
    this.history = history
  }

  /**
   * Navigates to other pages within the same web application
   * @param {string} url - The URL to navigate to
   * @param {NavigationOptions} options - Navigation options
   */
  async navigateInternal(url, options) {
    if (options.noHistory) {
      this.history.replace(url)
    } else {
      this.history.push(url)
    }

    // Returning false prevents MSAL from performing the redirect
    return false
  }
}
