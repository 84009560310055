import React from 'react'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import { instana as Monitoring } from '../../utils/monitoring/instana'
import { useAsync } from 'react-use'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { UserPreferencesContext } from '../preferences/UserPreferencesContext'
import { buildLaunchDarklyContext } from '../../userprofile/launchdarkly'

const LaunchDarklyProvider: React.FC = ({ children }) => {
  const profile = React.useContext(UserPreferencesContext)
  const ldContext = !profile.email ? { key: 'anonymous' } : buildLaunchDarklyContext(profile)

  Monitoring.trace(`LD Client ID: ${window.ENV.REACT_APP_LAUNCHDARKLY_KEY}`)
  Monitoring.trace(`LD Context: ${ldContext}`)

  const { value } = useAsync(() =>
    asyncWithLDProvider({
      clientSideID: window.ENV.REACT_APP_LAUNCHDARKLY_KEY,
      context: ldContext,
    }),
  )

  return pipe(
    value,
    O.fromNullable,
    O.match(
      () => null,
      (Provider) => <Provider>{children}</Provider>,
    ),
  )
}

export default LaunchDarklyProvider
